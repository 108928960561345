<style scoped>
/*
020202 black
0d324d dark blue
d00000 red
6ea4bf light blue
9da2ab grey
*/
* {
  -border: 1px dashed black;
}
.app {
}
.needsrefresh {
  position: absolute;
  top: 2.5%;
  left: 2.5%;
  width: 95%;
  height: 95%;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items:center;
  justify-content:center;
  border: 1px solid black;
  margin: auto;
  background-color: white;
}
.down {
}
.rest {
}
.nav {
}
.minimenu *{
  -border: 1px dashed black;
}
.minimenu {
  max-width: 40em;
  height: 2em;
  margin: 0 auto;
  padding: 0 0;
}
.minimenu ul {
  list-style-type: none;
  height: 100%;
  margin: 0 0;
  padding: 0 0;
}
.minimenu ul li {
  float: left;
  padding: 0 1em;
  height: 100%;
}
/*
.app > * {
overflow: hidden;
}
.grid {
display: grid;
gap: 10px;
grid-template-columns: 100% auto;
grid-template-areas:
"devtools"
"nav"
"sidebar"
"mininav"
"content"
}
.grid > div {
border: 1px solid black;
}
.mininav {
grid-area: mininav;
width: 100%;
height: auto;
}
@media only screen and (min-width: 1000px){
.grid {
grid-template-columns: 30% auto;
grid-template-areas:
"devtools  devtools"
"nav       mininav"
"sidebar   content"
"sidebar   content"
"sidebar   content"
;
}
}
.nav {
grid-area: nav;
text-align: center;
}
.devtools {
grid-area: devtools;
}
.codeplugslist {
grid-area: sidebar;
}
.view {
grid-area: content;
}
 */
</style>
<template>
  <div class="app">
    <div class="needsrefresh" v-if="needsrefresh">
      <div class="refreshmsg">
        <h1>Please refresh the page</h1>
        <br>
        <h2>(Ctrl+F5 or Cmd+F5 will do the trick)</h2>
      </div>
    </div> <!-- /needsrefresh -->
    <div class="minimenu">
      <ul>
        <li v-if="currentview != 'MainMenu'"> <router-link to="/">Main Menu</router-link> </li>
        <!--<li> <router-link to="/">Help</router-link> </li>-->
        <!--<li>view={{currentview}}</li>-->
        <li v-if="showdev"><button @click="toggledevmode">Dev {{devmode?1:0}}</button></li>
      </ul>
    </div>
    <router-view />


    <!--
      <CodeplugsList class="codeplugslist" />
      <div class="mininav">
      <Home :mini="true" />
      </div>
      <div class="view">
      <router-view />
      </div>
    -->
  </div> <!-- /app -->
</template>
<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: "App",
  components:{
  },
  created(){
    this.loadsession();
    this.loadDataFiles();
  },
  methods: {
    ...mapMutations('firmwares',['add_firmwares','clear_firmwares']),
    ...mapMutations('devtools',['toggledevmode']),
    async loadDataFiles(){
      const fwl = await fetch( "/data/all_firmware/files.json").then(r=>r.json());
      this.add_firmwares(fwl);
    },
    async loadsession(){
      //TODO: make new api endpoints, and use that api here and make it suck less
      //TODO: also, put data into vuex
      const mysess = await fetch("/api/users/",{ 
        //bare (no host) path will fail when going to localhost:port because API is on a different port, have to use dev.dmr.tools
        method:"OPTIONS"
      }).catch(e=>console.log("ERROR",e));
      console.log("logged in: ",mysess);
      if( mysess.ok ){
        this.myaccount = mysess;
      } else {
        console.log("ERROR2",mysess);
      }
    }
  },

  computed:{
    ...mapState('devtools',['devmode']),
    showdev(){
      return ["dev.dmr.tools","localhost","127.0.0.1"].includes(window.location.hostname) 
    },
    currentview(){
      return this.$route.name;
    },
    loggedin(){
      return this.myaccount != null;
    },
    needsrefresh(){
      //doesn't work!
      return window.needsrefresh;
    }
  },
  data(){
    return {
      myaccount: null,
      online: true,
    }
  },
}
/*
var data_sources = [
  new_data_source("commgroups",'/data/standard_commgroups.json'),
  new_data_source("channels",'/data/standard_channels.json'),
  new_data_source("chancomms",'/data/standard_chancomms.json'),
  new_data_source("radioid.net",'/data/Massachusetts.json'),
  new_data_source("fcc.js",'/data/WREQ214.json'),
  //new_data_source("fcc.js",'./data/WQTV843.json'),
  //new_data_source("fcc.js",'/data/WRDJ435.json'), //demos a repeater
  //new_data_source("fcc.js",'./data/WQTD650.json'),
  //new_data_source("fcc.js",'./data/KTW853.json'),
  //new_data_source("radioid.net",'./data/D312502.json'),
  new_data_source("commgroups",'/data/brandmeister.json'),
  new_data_source("commgroups",'/data/dmr-marc.json'),
]
ds_to_vue_data(app, data_sources);

TODO:
fix header differences
diff -y <(xxd tarxvf_tech_202012200334_MD-UV390.rdt) <(xxd codeplug1_tarxvf.rdt) |less
fix corruption issues! FUCK!
suspect the trailer isn't actually at the end in the MD-UV390
16 byte offset later in the file, persists for a while, but front is good
the correct codeplug has data 16 bytes later than mine, so my trailer at the end should be in the middle somewhere. probably should have trusted the farnsworth .json, eh?
the -W 160 on diff is necessary because otherwise the xxd output gets cut off when in diff's two column mode
diff -y --left-column  -W 160 <(xxd ~/projects/codeplugs/codeplug1.rdt) <(xxd ~/projects/codeplugs/codeplug1_tarxvf.rdt) |less

When i come back in the morning:
make a new component for the radio display
make a new component for the codeplug display
make them look pretty :)

https://codeburst.io/shared-state-and-routing-in-vue-js-7bfea06e44ec?gi=8518e879f3d4
make it a single component i guess? or use vuex to persist states...
 */
</script>
