<style scoped>
.channel {
  border: 1px solid black;
  margin: 1px;
}
.rochannel {
}
.rochannel label {
  display: inline;
}
</style>
<template>
  <div class="channel">
    <div class="rwchannel" v-if="rw">
      <button @click="debug">Debug</button>
      <label>Name:</label><input type="text" v-model="thischan['Channel Name']" />
      <label>Rx:</label><input type="number" v-model.lazy="thischan['Rx Frequency (MHz)']" />
      <label>Tx:</label><input type="number" v-model.lazy="thischan['Tx Offset (MHz)']" />
      <label>Mode:</label>
      <select v-model.lazy="thischan['Channel Mode']">
        <option :value="entry.index" v-for="(entry, idx) of thisdef.fields['Channel Mode'].indexedStrings" :key="idx">
        {{entry.string}}
        </option>
      </select>
      <div v-if="thischan['Channel Mode'] == 2">
        <label>Slot:</label>
        <select v-model.lazy="thischan['Repeater Slot']">
          <option :value="entry.index" v-for="(entry, idx) of thisdef.fields['Repeater Slot'].indexedStrings" :key="idx">
          {{entry.string}}
          </option>
        </select>
        <label>CC:</label><input type="text" v-model.lazy="thischan['Color Code']" />
        <label>Contact:</label><input type="text" v-model.lazy="thischan['Contact Name']" />
        <!--<GetByIdx :model="contacts" :setidx="thischan['Contact Name']" />-->
        <!--<GetByIdx :model="RX Group Lists" :idx="thischan['RX Group List']" />-->
        <label>RX Group:</label><input type="text" v-model.lazy="thischan['RX Group List']" />
      </div>
      <div v-else>
        <label>BW:</label>
        <select v-model.lazy="thischan['Bandwidth (KHz)']">
          <option :value="idx" v-for="(entry, idx) of thisdef.fields['Bandwidth (KHz)'].strings" :key="idx">
          {{entry}}KHz
          </option>
        </select>
        <label>CTCSS squelch (rx):</label>
        <input type="number" v-model.lazy="thischan['CTCSS/DCS Decode']" />
        <label>CTCSS access (tx):</label>
        <input type="number" v-model.lazy="thischan['CTCSS/DCS Encode']" />
      </div>
      <div class="advanced">
        <button @click="sanify">Set all advanced options to safe defaults</button>
        <div v-for="(fieldname, idx) in advancedfields" :key="idx">
          <h6>{{fieldname}}</h6>
          <input type="text" v-model="thischan[ fieldname ]" />
        </div>
      </div>
    </div>
    <div class="rochannel" v-else-if="onlyname">
      <div class="name">
        <span> {{thischan['id']}}{{ thischan['Channel Name'] }}</span>
      </div>
    </div>
    <div class="rochannel" v-else>
      <div class="name">
        <label>({{chanidx}}) Name:</label>
        <span> {{ thischan['Channel Name'] }}</span>
      </div>
      <div class="freq">
        <label>Rx:</label>
        <span> {{ thischan['Rx Frequency (MHz)'] }}</span>
      </div>
      <div class="name">
        <label>Tx:</label>
        <span> {{ thischan['Tx Offset (MHz)'] }}</span>
      </div>
    </div>
  </div>
</template>
<script>
//TODO: components for each data type (e.g. indexedStrings, etc)
//TODO: use the enables/disables info in the JSON
//TODO: colorcode doesn't work
import { mapState, mapMutations } from 'vuex';
//import Name from '@/components/source.vue'
//import { wait_for, save_data } from '@/libs/misc.js';
//import rf from '@/libs/rf.js';
//import tytdfu from '@/libs/dfu.js';
//import codeplugs from '@/libs/codeplug.js';

export default {
  name: "channel",
  props: ['chanidx','rw','onlyname'],
  components:{
  },
  computed:{
    //...mapState('devtools',['devmode']),
    ...mapState('codeplugs',['current_codeplug']),
    advancedfields(){
      const recordname = "Channels";
      const regularfields=[
        "Channel Name",
        "Rx Frequency (MHz)",
        "Tx Offset (MHz)",
        "Channel Mode",

        "Bandwidth (KHz)",
        "CTCSS/DCS Encode",
        "CTCSS/DCS Decode",

        "Contact Name",
        "RX Group List",
        "Repeater Slot",
        "Color Code",

        "Privacy",
        "Privacy Number",
      ];
      return this.thisdef.records[recordname].fieldTypes.map(fieldname=>this.thisdef.fields[fieldname].typeName).filter(x=>!regularfields.includes(x));
    },
    listfields(){
      const recordname = "Channels";
      return this.thisdef.records[recordname].fieldTypes.map(fieldname=>this.thisdef.fields[fieldname].typeName);
    },
    thischan(){
      return this.channels[this.chanidx];
    },
    thisdef(){
      return this.current_codeplug.def;
    },
    channels(){
      if( this.has_codeplug ){
        return this.current_codeplug.editor.Channels;
      } else {
        return [];
      }
    },
    has_codeplug(){
      console.log("has_codeplug");
      return this.current_codeplug != undefined;
    }
  },
  methods: {
    //...mapMutations('codeplugs',['add_codeplug']),
    debug(){
      window.el = this;
    },
    sanify(){
      for( const typename of this.advancedfields ){
        const oldval = this.thischan[typename];
        const defval = this.thisdef.fields[typename].defaultValue;
        try {
        this.thischan[typename] = defval;
        } catch (e){
          console.log("failed to set", typename, e);
        }
        console.log(typename, oldval, defval, "now: ", this.thischan[typename]);
      }
    },
  },
  data(){
    return {
    }
  }
}
</script>
