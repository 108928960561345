
const standard_offset = {
    "2m": 0.6,
    "70cm": 5,
    "1.25m":1.6,
    "10m":.1
}
function freq_to_band(f){
    const bands = [
        [136, 174, "2m"],
        [219, 225, "1.25m"],
        [400, 480, "70cm"],
        [902, 928, "33cm"]
    ];
    for (const band of bands){
        if( f >= band[0] && f <= band[1] ){
            return band[2];
        }
    }
    return "unknown band";

}
function standard_offset_from_freq(f){
    return standard_offset[ freq_to_band( f ) ];
}
function emission_designator_to_protocol( emd ){
    const emd2p = {
        "7K60FXW":"DMR",   
        "7K60FXD":"DMR",
        "7K60FXE":"DMR",
        "11K2F3E":"FM2.5", //"FM%d"%(FM_tx_deviation)

    }
    const proto = emd2p[emd];
    return proto ? proto: "unk";
}

export default {
    standard_offset,
    standard_offset_from_freq,
    freq_to_band,
    emission_designator_to_protocol
}
