<style scoped>
* {
  -border: 1px dashed grey;
}
label {
  display: inline;
}
button {
  margin: 2px 0px;
}



.spinner {
  animation: spin 1.4s infinite linear;
  position: relative;
  display: inline-block;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(180deg); }
  100% { transform: rotate(360deg); }
}

/*
@media only screen and (max-width: 767px){ 
@media only screen and (min-width: 768px){
*/
.radio {
  border: 3px outset grey ;
  border-radius: 10px;
  padding: .5em;
  margin: .1em;
  max-width: 50em;
}
.radio.disabled {
  filter: contrast(.5);
  opacity: .5;
}
progress {
  width: 100%;
}
@media only screen and (min-width: 640px){ 
  .radio {
    display: grid;
    grid-gap: .5em;
    grid-template-columns: auto auto;
    grid-template-areas:
    "description        connection"
    "actions            depiction"
    "actions            depiction"
    "status             depiction"
    ;
  }
  .actions {
    grid-area: actions;
  }
  .actions button, .actions select, .actions input {
    display: block;
    width: 100%;
    text-align: center;
  }
  .depiction {
    grid-area: depiction;
    filter: saturate(0) contrast(1.2);
    /* drop-shadow(.5em 1em 5px grey); */
  }
  .description {
    grid-area: description;
  }
  .description ul {
    list-style-type:none;
    margin:0 0;
    padding: 0;
  }
  .status {
    grid-area: status;
  }
  .connection {
    grid-area: connection;
    display:none;
  }
}
</style>
<template>
  <div class="radio" :class="{disabled:disabled}">
    <div class="actions">
      <button v-if="codeplugmode" @click="get_codeplug">Read Codeplug</button> 
      <button v-if="codeplugmode" @click="put_codeplug">Write Codeplug</button> 
      <!--<button v-if="devmode" @click="reboot">Reboot</button> -->
      <div><label>Select firmware file:</label>
      <select v-if="firmwaremode" v-model="selected_firmware_url" @change="statusmsg=''">
        <option value="null">Choose FW</option>
        <option value="uploadfromcomputer">Upload from my computer</option>
        <option v-for="fw in firmware_list" :key="fw.url" :value="fw.url">
        {{fw.name}}
        </option>
      </select>
      </div>
      <div v-if="selected_firmware_url=='uploadfromcomputer'"><label>Upload a firmware file from your computer:</label>
      <input v-if="firmwaremode" @change="upload_firmware" type="file" />
      </div>
      <div><label>{{statusmsg}}</label></div>
      <button v-if="firmwaremode" :disabled="!firmwareready" @click="firmware">Upgrade Radio</button> 
      <button v-if="recoverymode" @click="recover" disabled>Recover (coming soon)</button> 


      <button v-if="codeplugmode && device.hastools" @click="screenshot">Screenshot</button> 
      <button v-if="devmode && device.hastools" @click="dmesg">dmesg</button> 
      <!--<button v-if="devmode" @click="debug">Debug</button> -->
    </div>
    <img class="depiction" :src="imgpath" />
    <div class="connection">
      <h5 v-if="disabled">Disconnected</h5>
      <h5 v-if="!disabled">Connected</h5>
    </div>
    <div class="description">
      <ul>
        <!--<li class="name"><label>Radio: </label><span>{{device.name}}</span></li>-->
        <li class="model"><label>Radio Model: </label><span >{{device.model}}</span> </li>
        <li class="bands"><label>Bands: </label><span >{{device.bands.join(", ")}}</span></li>
        <!--<li class="tools"><label>Experimental Firmware: </label><span v-if="device.hastools">✔</span><span v-else>╳</span></li>-->
        <li v-if="true || device.dmrid != 0" class="dmrid"><label>DMR ID: </label><span v-if="device.dmrid != 0">{{device.dmrid}}</span><span v-else>(unknown)</span></li>
      </ul>
    </div>
    <div class="status">
      <div class="task" v-for="p of progress" :key="p.name">
        <label>{{p.name}}</label>
        <!--<span v-if="p.finished">✔</span>-->
        <!--<span v-else-if="p.errored">╳</span>-->
        <!--<span class="spinner" v-else>/</span>-->
        <progress class="progress " :value="p.current" :max="p.max" ></progress>
      </div>
      <img v-if="screenshotbloburl" :src="screenshotbloburl" />
    </div>

  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { wait_for, fetchblob } from '@/libs/misc.js';
import codeplugs from '@/libs/codeplug.js';
import rf from '../libs/rf.js';
export default {
  name: "ConnectedRadio",
  props: ["device", "purpose"], 
  mounted(){
    this.pingtimer = window.setInterval(this.ping, 50);
    //and make sure to clear the timer in beforeUnmount and when the ping fails
  },
  beforeUnmount(){
    clearInterval(this.pingtimer);
  },
  methods: {
    async dmesg(){
      const dmesg = await this.device.dfu.getdmesg();
    },
    async screenshot(){
      const bmp = await this.device.dfu.get_screen_bmp();
      console.log("BMP",bmp);
      const blob = new Blob( [bmp], { type: "image/bmp" } );
      console.log(blob);
      const url = window.URL.createObjectURL(blob);
      console.log(url);
      this.screenshotbloburl = url;
    },
    reboot(){
      //TODO move this and other functions in here?
      this.$parent.reboot(this.device);
    },
    get_codeplug(){
      this.$parent.get_codeplug(this.device);
    },
    put_codeplug(){
      this.$parent.put_codeplug(this.device);
    },
    debug(){
      console.log(this.device);
      window.d = this.device;
    },
   
    async ping(){
      const alive = await this.device.dfu.ping();
      this.disabled = ! alive;
      if( ! alive ){
        console.log(this.disabled);
        clearInterval(this.pingtimer);
      }
    },
    
    async firmware(){
      console.log(this.selected_firmware_url);
      if( this.selected_firmware_url ){
        let url = this.selected_firmware_url;
        let fw_bytes;
        if( url == 'uploadfromcomputer' ){
          if( this.localfirmware ){
            fw_bytes = this.localfirmware; //button should be disabled to make sure the file is ready;
          } else {
            return false; //TODO: error
          }
        } else {
          this.statusmsg = "downloading firmware";
          fw_bytes = await fetchblob( this.selected_firmware_url );
          this.statusmsg = "firmware ready!";
        }
        console.log(fw_bytes);
        await this.device.dfu.firmware_upload(fw_bytes);
      } else {
        const error=console.log;
        error("invalid firmware url");
      }
    },
    async upload_firmware(){
      const files = event.target.files
      this.statusmsg = "uploading firmware";
      for( let file of files ){
        try {
          if( ! file.name.endsWith(".bin") ){
            console.log("File does not have .bin extension! Are you sure this is a firmware?");
          }
          const bin = await codeplugs.readFileBytes(file); 
          this.localfirmware = bin;
        } catch ( e ){
          console.log(e);
          //this.show_error(e, e, "upload", file.name, "upload_codeplug"); //todo - move this to where the original exception is
          //throw(e);  //TODO: log with sentry!
        }
      }
      this.statusmsg = "local firmware ready!";
    },
  },
  computed: {
    ...mapState('devtools',['devmode']),
    ...mapState('firmwares',['loaded_firmwares','current_firmware']), 
    firmwareready(){
      return (![null,"null","uploadfromcomputer"].includes(this.selected_firmware_url)) 
        || (this.selected_firmware_url == 'uploadfromcomputer' && this.localfirmware != null);
    },
    codeplugmode(){
      return this.currentview == "USB"; //TODO fix this name
    },
    firmwaremode(){
      return this.currentview == "firmware";
    },
    recoverymode(){
      return this.currentview == "recover"; 
    },
    currentview(){
      return this.$route.name;
    },
    firmware_list(){
      if( this.loaded_firmwares.length ){
        return this.loaded_firmwares.map(x=>Object({url:"/data/all_firmware/" + x, name:x}));
      } else {
        return []
      }
    },
    devstatus(){
      return [this.device.dfu.statusString, this.device.dfu.stateString];
    },
    progress(){
      return this.device.dfu.progress_get();
    },
    imgpath(){
      let bandselect = "";
      //allows for long VHF antenna images if i can get some
      //if( this.device.bands.length == 1){
      //bandselect=this.device.bands[0];
      //}
      return `/images/radio_assets/cropped/${this.device.model}${bandselect}.png`.toLowerCase();
    },
  },
  data(){
    return {
      screenshotbloburl: null,
      selected_firmware_url: null,
      disabled: false,
      pingtimer: null,
      localfirmware: null,
      statusmsg: "",
    }
  }


}
</script>
