import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import MainMenu from '@/views/Main.vue'
import WebDFURadio from '@/components/webdfuradio.vue'
import Keyloader from '@/components/keyloader.vue'
import DMRID from '@/components/dmrid.vue'
import ManualCPS from '@/components/manualcps.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'MainMenu',
    component: MainMenu
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import([> webpackChunkName: "about" <] '../views/About.vue')
    component: About
  },
  {
    path: '/usb',
    name: 'USB',
    component: WebDFURadio,
  },
  {
    path: '/flashfirmware',
    name: 'firmware',
    component: WebDFURadio,
  },
  {
    path: '/recoverradios',
    name: 'recover',
    component: WebDFURadio,
  },
  {
    path: '/keyloader',
    name: 'Keyloader',
    component: Keyloader,
  },
  {
    path: '/dmrid',
    name: 'ID Manager',
    component: DMRID
  },
  {
    path: '/manualcps',
    name: 'Manual CPS',
    component: ManualCPS
  },
  {
    path: '/autocps',
    name: 'Auto CPS',
    component: Home
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
