<style scoped>
.zone {
}
.name {
  clear:both;
}
.AChannels {
  float: left;
}
.BChannels {
  float: right;
}
.entry {
  width: 100%;
}
</style>
<template>
  <div class="zone">
    <button @click="debug">debug</button>
    <div class="name">
    <label>Name:</label><input type="text" v-model="thiszone['Zone Name']" />
    </div>
    <h4>Channel List</h4>
    <div class="Channels" v-if="! ABChannels">
      <div class="entry" v-for="(chanidx,zonechanidx) in thiszone['Channels']" :key="zonechanidx" 
        draggable="true" 
        @dragstart="dodragstart($event, zonechanidx)"
        @dragend="dragend"
        @dragover.prevent
        @dragenter.prevent="dragenter"
        @dragleave.prevent="dragleave"
        @drop.capture="dragdrop($event, zonechanidx)"
        >
        <!--{{chanidx-1}}-->
        <!--<button @click="select(chanidx-1)">Modify</button>-->
        <Channel @click="select(chanidx-1)" v-if="chanidx!=0" :chanidx="chanidx-1" :rw='false' :onlyname="true" />
        <!--<button @click="">del from zone</button>-->
      </div>
    </div>

    <div class="explanation" v-if="ABChannels">
      There are two lists of channels per zone for each virtual receiver of the radio.
    </div>
    <div class="AChannels" v-if="ABChannels">
      <h4>A</h4>
      <div v-for="(chanidx,zonechanidx) in thiszone['A Channels']" :key="zonechanidx">
        <div class="entry" 
                           draggable="true" 
                           >
                           <Channel v-if="chanidx!=0" :chanidx="chanidx-1" :rw='false' :onlyname="true"/>
        </div>
      </div>
    </div>
    <div class="BChannels" v-if="ABChannels">
      <h4>B</h4>
      <div v-for="(chanidx,zonechanidx) in thiszone['B Channels']" :key="zonechanidx">
        <div class="entry" 
                           draggable="true" 
                           >
        <Channel v-if="chanidx!=0" :chanidx="chanidx-1" :rw='false' :onlyname="true" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//TODO: make channels draggable within zone
//https://github.com/anish2690/vue-draggable-next/blob/master/src/VueDraggableNext.ts
import { mapState, mapMutations } from 'vuex';
//import Name from '@/components/source.vue'
import Channel from '@/components/channel.vue'
//import { wait_for, save_data } from '@/libs/misc.js';
//import rf from '@/libs/rf.js';
//import tytdfu from '@/libs/dfu.js';
//import codeplugs from '@/libs/codeplug.js';

export default {
  name: "zone",
  props: ['zoneidx'],
  components:{
    Channel,
  },
  computed:{
    //...mapState('devtools',['devmode']),
    ...mapState('codeplugs',['current_codeplug']),
    ABChannels(){
      return this.thiszone && Object.keys(this.thiszone).includes("A Channels");
    },
    thiszone(){
      return this.zones[this.zoneidx];
    },
    zones(){
      if( this.has_codeplug ){
        return this.current_codeplug.editor.Zones;
      } else {
        return [];
      }
    },
    has_codeplug(){
      console.log("has_codeplug");
      return this.current_codeplug != undefined;
    }
  },
  methods: {
    //...mapMutations('codeplugs',['add_codeplug']),
    debug(){
      console.log(this);
    },
    select(chanidx){
      this.$emit('selectchannel', chanidx);
    },
    zonermchan(chanidx){
      return "";
    },
    dodragstart(evt, zchanidx){
      console.log("start");
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('zchanidx', zchanidx); //could be set to a csv entry for the channel i guess
      evt.target.style.opacity = .4;
    },
    dragend(evt){
      console.log("end",evt);
      evt.target.style.opacity = 1;
    },
    dragover(evt){
      console.log("over",evt);
    },
    dragenter(evt){
      console.log("enter",evt);
      //evt.target.style.border = "1px solid red";
    },
    dragleave(evt){
      console.log("leave",evt);
      //evt.target.style.border = "none";
    },
    dragdrop(evt, newzonechanidx){
      console.log("drop",evt);
      evt.target.style.border = "1px solid green";
      const oldzonechanidx = evt.dataTransfer.getData("zchanidx");

      //swap for now to prove it's possible
      console.log(`Swap ${oldzonechanidx} with ${newzonechanidx}`);
      console.log(this.thiszone);
      const temp = this.thiszone.Channels[newzonechanidx];
      this.thiszone.Channels[newzonechanidx] = this.thiszone.Channels[oldzonechanidx];
      this.thiszone.Channels[oldzonechanidx] = temp;

      //TODO: bug. Only works on the first zone for whatever reason

      //TODO: to do it right, 
      // can pull selected channel idx out of the zone
      // iterate over full zone and re-render it without that item, bringing everything to lowest indexes
      // on @drop, re-render whole list with selected channel idx inserted there 
      // (i guess?)
    },
  },
  data(){
    return {
    }
  }
}
</script>
