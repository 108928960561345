<style scoped>
.manualcps {
}
.channel_editor {
  float: left;
  width: 49%;
}
.zone_editor {
  float: right;
  width: 49%;
}
</style>
<template>
  <div class="manualcps">
    <div v-if="has_codeplug">
      <div class="channel_editor">
        <label>Channel number</label>
        <input type='number' min=0 :max="channels.length-1" v-model="chanidx"/>
        <button @click="addToZone(chanidx, zoneidx)">Add to current Zone</button>
        <Channel :chanidx="chanidx" :rw="true" />
      </div>
      <div class="zone_editor">
        <label>Zone number</label>
        <input type='number' min=0 :max="zones.length-1" v-model="zoneidx"/>
        <Zone @selectchannel="setselectedchannel" :zoneidx="zoneidx" />
      </div>
    </div>
    <div v-else>
      <h2>Select a codeplug first</h2>
    </div>
  </div>
</template>
<script>
//TODO: will need pagination to be fast enough?
//TODO: should still optimize the make_editor object so it doesn't slow this thing down too much

import { mapState, mapMutations } from 'vuex';
//import Name from '@/components/source.vue'
import Channel from '@/components/channel.vue'
import Zone from '@/components/zone.vue'
//import { wait_for, save_data } from '@/libs/misc.js';
//import rf from '@/libs/rf.js';
//import tytdfu from '@/libs/dfu.js';
//import codeplugs from '@/libs/codeplug.js';

export default {
  name: "manualcps",
  props: [],
  components:{
    Channel,
    Zone,
  },
  computed:{
    ...mapState('devtools',['devmode']),
    ...mapState('codeplugs',['current_codeplug']),
    channels(){
      if( this.has_codeplug ){
        return this.current_codeplug.editor.Channels;
      } else {
        return [];
      }
    },
    zones(){
      if( this.has_codeplug ){
        return this.current_codeplug.editor.Zones;
      } else {
        return [];
      }
    },
    has_codeplug(){
      console.log("has_codeplug");
      return this.current_codeplug != undefined;
    }
  },
  methods: {
    //...mapMutations('codeplugs',['add_codeplug']),
    setselectedchannel(newchanidx){
      this.chanidx = newchanidx;
    },
    addToZone(chanidx, zoneidx){
      console.log(`add CH${chanidx} to Z${zoneidx}`);
      //TODO: i need better primites in the editor for this, i think
      //TODO: want a better interface - 
      //  like a Select2 for generating a list of channels to auto-add to a zone
    },
  },
  data(){
    return {
      chanidx: 0,
      zoneidx: 0,
    }
  }
}
</script>
