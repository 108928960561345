
<style scoped>
.mainmenu {
  width: 20em;
  margin-left: auto;
  margin-right: auto;
}
.submenu {
}
.submenu h2 {
  width: 9em;
  margin-left: auto;
  margin-right: auto;
  font-family: Courier New;
}
.submenu ul {
  overflow: visible;
  width: 16em;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  list-style-type: none;
}
.submenu ul li {
  margin-left: 1em;
  font-family: Helvetica;
}
</style>
<template>
    <div class="mainmenu">
      <div class="submenu" hidden>
        <h2>Codeplugs</h2>
        <ul>
          <li>Start Fresh</li>
          <li>My Computer</li>
          <li>The Cloud<sup>™️</sup></li>
          <li class=""><router-link to="/usb"></router-link></li>
          <li class=""><router-link to="/usb">My Radios</router-link> <img class="small" src="/images/usb3.svg"/> </li>
        </ul>
      </div>
      <div class="submenu">
        <h2 class="">Firmware
        <!-- TODO: make this a help/explanation link/hover -->
        </h2>
        <ul>
          <li class=""><router-link to="/flashfirmware">Upgrade</router-link><img class="small" src="/images/usb3.svg"/> </li>
          <!--<li class=""><router-link to="/recoverradios">Recover</router-link><img class="small" src="/images/usb3.svg"/> </li>-->
        </ul>
      </div>
      <div class="submenu">
        <h2>DMR.Tools</h2>
        <ul>
          <li> <router-link to="/about">About</router-link> </li>
          <li>Version <a href="https://dmr.tools/" target="_blank"><span class="version"> v0.6.0 </span></a></li>
          <li>Made by <a href="https://tarxvf.tech" target="_blank">TARXVF</a></li>
          <li hidden>
            <div class="login-menu">
              <span v-if="online && loggedin"><a href="https://dev.dmr.tools/api-auth/logout/?next=/">Logout</a></span>
              <span v-if="online && !loggedin"><a href="https://dev.dmr.tools/api-auth/login/?next=/">Login</a></span>
              <span v-if="!online">Offline</span>
            </div>
          </li>
        </ul>
      </div>
    </div> <!-- /mainmenu-->
</template>
<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: "MainMenu",
  props: [],
  computed:{
    ...mapState('devtools',['devmode']),
  },
  methods:{
  },
  data(){
    return {
      loggedin: false,
      online: true
    }
  }
}
</script>
