<style scoped>
.dmrid {
}
</style>
<template>
  <div class="dmrid">
    <ul>
    <li v-for="(id,idx) in idlist" :key="id">
      {{id}} <button @click="idlist.splice(idx,1)">Del</button>
    </li>
    </ul>
    <input type="number" v-model="newid" />
    <button @click="addID(newid)" >Add by ID</button>

    <input type="text" v-model="newcall" />
    <button @click="addID(findID(newcall))" >Add by call</button>
    
    <button @click="gen">Gen Codeplugs</button>
  </div>
</template>
<script>
//TODO: https://vue-select.org/
import { mapState, mapMutations } from 'vuex';
//import Name from '@/components/source.vue'
//import { wait_for, save_data } from '@/libs/misc.js';
//import rf from '@/libs/rf.js';
//import tytdfu from '@/libs/dfu.js';
//import codeplugs from '@/libs/codeplug.js';

export default {
  name: "dmrid",
  props: [],
  components:{
  },
  computed:{
    //...mapState('devtools',['devmode']),
    ...mapState('codeplugs',['current_codeplug']),
    idmap(){
      return this.callsignmap;
    }
  },
  methods: {
    ...mapMutations('codeplugs',['add_codeplug']),
    gen(){
      const orig = this.current_codeplug;
      for( const id in this.idlist ){
        let newcp = orig; //TODO: LOL NO THIS DOESN"T COPY IT YOU MORON
        newcp.editor["General Settings"]["Radio ID"] = id;
        this.add_codeplug(newcp); //TODO need a new_codeplug function here so we can work with the raw rdt and start fresh to avoid the deep/shallow copy issue a little better
        //TODO need a duplicate_codeplug function too
      }
    },
    addID(num){
      console.log("addID",num);
      this.idlist.push(num);
    },
    lookupID(num){
      return;
    },
    findID(name_or_call){
      console.log("findID",name_or_call);
      return this.callsignmap[name_or_call][0];
    }
  },
  data(){
    return {
      idlist:[1337],
      callsignlist:[],
      callsignmap:{
        "W2FBI":[3125250,3125404,3125405],
        "WM4CH":[3125406]
      },
      newcall:null,
      newid:null,
    }
  }
}
</script>
