//TODO: list of radioids to generate codeplugs for
//      so take in a codeplug, modify the encryption keys uniformly
//      generate a modified codeplug for each radio id
//TODO findings:
//encryption keys are read and wrote backwards to editcp
//compare to OEM software and fix if necessary
//my code appears to go left to right, so that may be an issue on their end
//
//loading two codeplugs fails - possible there's a conflict for the original div, rather than re-assigning vue data.editor or something
//
//https://github.com/DaleFarnsworth-DMR/codeplug/blob/master/field.go
//fetch( "/data/zones.json").then(r=>r.json()).then(j=>z=j);

import { readFile, assert, grouppairs, sortbykey } from './misc.js';
import rf from '../libs/rf.js';
// bit/byte converters
function bytes2string(data){
    return data.map(c=> String.fromCharCode(c)).join("");
}
function dec2bin(dec){
    return (dec >>> 0).toString(2);
}
function bitmask2value( maskedbits, values ){
    return values[Math.log2( maskedbits )];
}
export function uint8s_to_int( lst ){ //why do i have this _and_ assemble_le?
    const lr = lst.reverse();
    let n = 0;
    for( let i = 0; i < lst.length; i++){
        n <<= 8;
        n |= lr[i];
    }
    return n;
}


function cstr2js(data){
    //expects an array of ints, not a uint8 array
    const end_of_ascii_string = data.indexOf(0);
    let name;
    if( end_of_ascii_string != -1 ){
        name = bytes2string(data.slice(0,end_of_ascii_string));
    } else {
        name = bytes2string(data);
    }
    return name;
}
function js2cstr(s){
    //expects a js string
    const sint = Array.from(s).map(x=>x.charCodeAt());
    sint.push(0); //terminating null byte
    return new Uint8Array(sint);
}
function test_js_2_4_cstr(){
    assert(cstr2js(Array.from(js2cstr("test"))) == "test")
    assert(cstr2js(Array.from(js2cstr(""))) == "")
}


function utf16str2js(data){
    //expects an array of ints, not a typed array
    //var end_of_ascii_string = data.indexOf(0);
    //console.log(data);
    const x = grouppairs(data).map(x=>x[0]); //only support ascii
    return cstr2js(x);
}
function jsstr2utf16(s){
    return new Uint8Array(
        Array.from(s).map(x=>[x.charCodeAt(),0]).flat()
    )
}
function test_js_2_4_utf16str(){
    assert(utf16str2js(Array.from(jsstr2utf16("test"))) == "test")
    assert(utf16str2js(Array.from(jsstr2utf16(""))) == "")
}



function assemble_le(bytes){
    let n = 0;
    for(let i = 0; i < bytes.byteLength; i++){
        n |= bytes[i] << (i*8);
    }
    return n;
}
function dissemble_le(somenum,sz){
    if( sz == undefined ){
        sz = 4; //assume 32 bit
    }
    assert(somenum >= 0);
    const bs = [];
    let acc = somenum;
    while( bs.length < sz ){
        bs.push(acc & 0xff);
        acc >>= 8;
    }
    //console.log("somenum:",somenum, bs);
    return new Uint8Array(bs);
}
function dissemble_le_sz(sz){
    return (val)=>dissemble_le(val,sz);
}


function fromBCD(data){
    let out = [];
    for( let i = data.length-1; i >=0; i--){
        out.push((data[i] & 0xf0)>>4);
        out.push(data[i] & 0x0f);
    }
    return parseFloat(out.join(""));
}
function toBCD(val){
    let nibbles = Array.from(String(val)).map(x=>parseInt(x));
    let nibbles2 = grouppairs(nibbles);
    let bs = nibbles2.map(x=>x[0]<<4 | x[1]);
    //console.log(val,nibbles,nibbles2,bs);
    return new Uint8Array(bs.reverse()); 
}
function test_bcd(){
    assert(fromBCD(toBCD(45112500)) == 45112500)
}


function hex2bytes(s){
    let pairs = grouppairs(s)
    let bytes = pairs.map(x=>parseInt(x,16));
    return new Uint8Array(bytes);
}
function bytes2hex(data){
    return Array.from(data).map(x=>x.toString(16).padStart(2,"0")).join("");
}
function test_hex_2_4_bytes(){
    assert(bytes2hex(hex2bytes("ff")) == "ff")
    assert(bytes2hex(hex2bytes("00")) == "00")
}
function bits_to_int(data,ft){
    //candidate for cleanup TODO
    let bo = ft.bitOffset;
    let bs = ft.bitSize;
    const mask = (2**bs)-1; //so size of 2 gives 0b11, 3 gives 0b111, etc
    //we have an array of bits as 8bit bytes
    //[ 0x3f, 0x81]
    //== [0011_1111, 1000_0001]
    //let's say bo is 2
    //and bs is 7
    //so it spans byte boundaries and such - does that every happen?
    //
    //cat codeplugs.json | grep bitSize|sort  |uniq -c |sort -k 3n
    //suggests that in practice, anything >= 8 bits is always integer multiples of 8 bits
    //so those  are already taken care of with slice!
    //and that also means we probably don't have any bits crossing byte boundaries.
    //  (probably)
    //just need to find the right byte that has what we need, and mask and dice until we get only what we need
    //
    assert(bs < 8); //only call us after handling the byte-aligned byte-sized stuff already

    let byteidx = Math.floor(bo/8); //so bit 2 is in byte 0, bit 9 is in byte 1, etc
    let Bo = bo - (byteidx*8); //get the bit offset in this byte, so 
    assert(bs+Bo <= 8); //error out if we actually do cross a byte boundary!
    //if bo == 9, Bo == 1
    //if bo == 2, Bo == 2, etc
    let b = data.subarray(byteidx,byteidx+1);
    let bO = 8 - Bo - bs; //get the amount to shift right by
    const ret = (b >> bO) & mask; //shift it, and mask it so we on;y get what we asked for
    //console.log("bits_to_int: ",data, ft, "bo",bo, "bs",bs, "byteidx",byteidx, "Bo", Bo, "b", b, "bO", bO, "mask",mask,"(b>>bO)&mask",ret);
    return ret;
}
function set_bits_of_int(existing_value, ft, newvalue){
    //assumes you're only working on a single byte...
    let bo = ft.bitOffset;
    let bs = ft.bitSize;
    assert(bs <= 8, "trying to work on more than a byte" );
    assert(existing_value <= 255 && newvalue <= 255, "values out of range set_bits_of_int" );
    assert(newvalue < 2**bs && newvalue >= 0, "new value is too big (or too small)");

    const byteidx = Math.floor(bo/8); //so bit 2 is in byte 0, bit 9 is in byte 1, etc
    const Bo = bo - (byteidx*8); //get the bit offset in this byte, so 
    const bO = 8 - Bo - bs; //get the amount to shift by

    const mask = (2**bs)-1; //so size of 2 gives 0b11, 3 gives 0b111, etc
    const imask = 0xff - mask;
    const shifted_newvalue = newvalue << bO;

    const clean_existing = existing_value & imask;
    //console.log("existing value, newvalue: ",existing_value, newvalue);
    //console.log("mask, imask: ",mask,imask);
    //console.log("clean existing: ",clean_existing);
    //console.log("shifted new: ",shifted_newvalue);
    return clean_existing | shifted_newvalue;
}
//assert(set_bits_of_int(0,{bitOffset:7,bitSize:1},1), 1 );
//assert(set_bits_of_int(0,{bitOffset:0,bitSize:1},1), 0x80, "0,1,1 failure");

function test_bits_to_int(){
    let x = [
        new Uint8Array([0x3f,0x81]),
        new Uint8Array([0x3f,0x81]),
    ]
    let y = [
        {
            bitOffset: 2,
            bitSize: 3,
        },
        {
            bitOffset: 7,
            bitSize: 1,
        }
    ]
    let z = [
        3,
        1
    ]
    for( let i = 0; i < x.length; i++){
        assert(bits_to_int(x[i], y[i]) == z[i]);
    }
}


export const tests = {
    test_bits_to_int,
    test_bcd,
    test_js_2_4_cstr,
    test_js_2_4_utf16str,
    test_hex_2_4_bytes,
};














export function ident_codeplug_radio_rdt(data){
    let intended_radio = data.slice(0x125,0x125+32)
    let radioname = cstr2js(Array.from(intended_radio));
    return radioname;
}
function make_editor(codeplug_format, radioname, data){
    //this is slow to build - 10k+ contacts and 3k+ channels and 250+ zones
    //will do that.

    let getValueTypes = {
        name: data => utf16str2js(Array.from(data)),
        introLine: data => utf16str2js(Array.from(data)),
        contactName: data => utf16str2js(Array.from(data)),
        textMessage: data => utf16str2js(Array.from(data)),
        ascii: data => cstr2js(Array.from(data)),

        biFrequency: data=> fromBCD(data)/10,
        frequency: data=> fromBCD(data)/1e5,
        frequencyOffset: data=> fromBCD(data)/1e5,

        callID: assemble_le,
        ctcssDcs: function(data){
            if( assemble_le(data) == 65535 ){
                return 0;
            }
            return fromBCD(data)/10
        },
        contactListIndex: assemble_le,
        memberListIndex: assemble_le,
        gpsListIndex: assemble_le,
        spanList: x=>x,
        listIndex: assemble_le,

        hexadecimal32: bytes2hex,
        hexadecimal4: bytes2hex,
        indexedStrings: x=>x,
        iStrings: (data,ft)=>{ 
            //problem - there are iStrings that are byte based and iStrings that aren't.
            //
            let idx = assemble_le(data);
            return ft.strings[ idx ];
        },

    }
    let setValueTypes = {
        name: jsstr2utf16,
        introLine: jsstr2utf16,
        contactName: jsstr2utf16,
        textMessage: jsstr2utf16,
        ascii: js2cstr,
        indexedStrings: x=>x,
        spanList: x=>x,
        bandwidth: x=>x,

        ctcssDcs: (val)=>{
            let scaled;
            if( val == 0 || val == null || val == undefined ){
                return dissemble_le(65535,2);
            } else {
                scaled = Math.floor(val * 10);
            }
            return toBCD(scaled);
        },
        frequency: (val)=>{
            let scaled = Math.floor(val * 1e5);
            return toBCD(scaled);
        },
        frequencyOffset: (val)=>{
            let scaled = Math.floor(val * 1e5);
            return toBCD(scaled);
        },
        biFrequency: (val)=>{
            let scaled = Math.floor(val * 10);
            return toBCD(scaled);
        },

        hexadecimal32: hex2bytes,
        hexadecimal4: hex2bytes,
        callID: dissemble_le_sz(3),
        listIndex: dissemble_le_sz(2),
        contactListIndex: dissemble_le_sz(2),

    }
    let e = {};
    function isDel(rt,data){ //untested, not sure
        if( rt.delDesc == undefined ){ return false; }
        let dof = rt.delDesc.offset;
        let sz = rt.delDesc.size;
        let equal_if_del = rt.delDesc.value;
        let cmp = data.slice(dof,dof+sz);
        if( cmp == equal_if_del ){
            return true;
        }
        console.log(cmp,equal_if_del);
        return false;
    }
    function make_field_array(pare,rt,ft,record_data){
        let o = []
        for( let i = 0; i < ft.max; i++ ){
            let idx = i;
            let offset = ft.bitOffset/8 + idx*ft.bitSize/8;
            let thesebytes = record_data.subarray(offset, offset+ft.bitSize/8);
            make_field(o, rt,ft,thesebytes,i);
        }
        pare[ft.typeName] = o;
    }
    function make_value(pare, rt, ft, field_data, name){
        let get = function(){
            if( ft.bitOffset % 8 == 0 && ft.bitSize %8 == 0){ //byte boundaries yay!  if( getValueTypes[ft.valueType] ){
                if( getValueTypes[ft.valueType] ){
                    return getValueTypes[ft.valueType](field_data,ft);
                } else {
                    return field_data;
                }
            } else {
                let bits = bits_to_int(field_data, ft);
                //console.log("[341]bits:",bits);
                if( getValueTypes[ft.valueType] ){
                    return getValueTypes[ft.valueType](bits,ft);
                } else {
                    return bits;
                }
                //return `${ft.typeName} isn't byte-based: boundary:${ft.bitOffset %8} size:${ft.bitSize%8}`;
                //can i even handle this nicely in javascript?
            }
        }
        let set = function(val){
            //TODO: can i used a subarray to just write to the field data directly? 
            //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/TypedArray/subarray
            //
            //need a handle to the codeplug
            //need a byteoffset into the full thing, plus the size of course
            //if not byte-aligned and byte-sized
            //  need to pull current value to handle bits properly 
            //  then modify that value
            //then overwrite the existing content by splicing into the main codeplug file
            //which may require padding the value or truncating it
            console.log(pare,rt,ft,name,val);
            if( ft.bitOffset % 8 == 0 && ft.bitSize %8 == 0){ //byte boundaries yay!
                if( setValueTypes[ft.valueType] ){
                    let x = setValueTypes[ft.valueType](val,ft);
                    let offset = rt.offset;
                    //console.log(offset)
                    if( pare._idx != undefined ){
                        offset += pare._idx * rt.size;
                    }
                    //console.log(offset)
                    offset = offset + ft.bitOffset/8;
                    //console.log(offset)
                    let sz = ft.bitSize/8;
                    if( ft.max ){
                        let idx = name;
                        offset += idx*sz;
                    }
                    console.log(bytes2hex(e._cp.slice(offset,offset+sz)));
                    writeBytes(e._cp, x, offset, sz);
                    console.log(bytes2hex(e._cp.slice(offset,offset+sz)));
                } else {
                    throw `unimplemented valueType to set: ${ft.valueType}`;
                }
            } else {//not byte aligned, or smaller than bytes, or whatever
                //we don't actually handle values that cross byte boundaries, just smaller-than-a-byte values
                if( setValueTypes[ft.valueType] ){
                    //should only have to handle single bytes at a time
                    //core of this: get current value. Make sure bits at selected position equal what we say.
                    //then assert we haven't changed the other bits
                    //console.log("set val to ft", ft.bitOffset, ft.bitSize, ft);

                    //copied and pasted ... 
                    let offset = rt.offset;
                    //console.log(offset);
                    if( pare._idx != undefined ){
                        offset += pare._idx * rt.size;
                    }
                    //console.log(offset);
                    offset = offset + ft.bitOffset/8;
                    //console.log(offset);
                    let sz = ft.bitSize/8;
                    if( ft.max ){
                        let idx = name;
                        offset += idx*sz;
                    } 
                    //console.log(offset);
                    offset = Math.floor(offset);
                    //console.log(offset);
                    ////
                    //writeBytes(dst,modified,offset,1);
                    //writeBytes(e._cp, x, offset, sz);
                    //console.log(e._cp[offset]);
                    //const old = get();
                    const old = e._cp[offset];
                    const newval = setValueTypes[ft.valueType](val,ft);
                    const modified = set_bits_of_int(old, ft, newval);
                    e._cp[offset] = modified;
                    assert(get(), val, "failure to verify set");

                } else {
                    throw `unimplemented valueType to set: ${ft.valueType}`;
                }
            }
            function writeBytes(dst,src,offset,size){
                for( let i = 0; i < size; i++ ){
                    dst[offset+i] = src[i];
                }
            }
        }
        Object.defineProperty(pare, name, {
            get: get,
            set: set,
            configurable: true,
            enumerable: true,
        }); 
    }
    function make_field(pare,rt,ft,field_data,name){
        return make_value(pare,rt,ft,field_data,name);
    }
    function add_field(pare, rt, ft, record_data){
        //and fields can have arrays too (dammit)
        if( ft.max ){
            make_field_array(pare,rt,ft,record_data);
        } else {
            let bo = Math.floor(ft.bitOffset/8);
            let bs = Math.floor(ft.bitSize/8);
            let field_data;
            if( bs == 0 ){ //if it's less than a byte in size, all our bit-handling expects to have to handle offsets directly
                field_data = record_data;
            } else {
                field_data = record_data.subarray(bo,bo+bs)
            }
            if( field_data.length == 0 ){
                console.log(ft, bo,bs, record_data);
                throw(new Error("Could not subarray bytes"));
            }
            //I'm sorry, I know
            //TODO! make this whole file more consistent
            make_field(pare, rt, ft, field_data, ft.typeName);
        }
    }
    function make_record(rt,data,idx){
        let o = {};

        //useful for debugging, commented out to speed things up a bit
        //o["_rt"] = rt;
        //o._rt.fields= {};
        //o["_bytes"] = data;
        for( let f of rt.fieldTypes ){ 
            let ft = codeplug_format.fields.filter(x=>x.type==f)[0];
            //o._rt.fields[f] = ft;
            o._idx = idx
            add_field(o,rt,ft,data);
        }
        return o;

    }
    function make_record_array(rt,data){
//TODO: that proxy in make_editor needs to implement the iterators so i don't have to do this Channels.length thing
//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Iterators_and_Generators
        function return_record(rt,idx,data){
            let offset = rt.offset + idx*rt.size;
            let thesebytes = data.subarray(offset, offset+rt.size+10);
            return make_record(rt, thesebytes, idx);
        }
        const handler1 = {
            get: function(target, prop, receiver ){
                //console.log("record_array",target.rt.typeName, typeof(prop),prop);
                if( prop == Symbol.iterator ){
                    console.log("Need to implement iterator!");
                    return function*(){
                        for( let i = 0; i < target.rt.max; i++){
                            if( target[propnum] == undefined ){
                                target[propnum] = return_record(target.rt,propnum, target.data);
                            }
                            yield target[propnum];
                        }
                    }
                }
                if( typeof(prop) != typeof("") ){
                    return;
                }
                const propnum = parseInt(prop);
                if( prop == "length" ){
                    return target.rt.max;
                } else if (propnum >= 0 && propnum < target.rt.max ){
                    if( target[propnum] == undefined ){
                        target[propnum] = return_record(target.rt,propnum, target.data);
                    }
                    return target[propnum];
                }
            },
        };
        const proxy1 = new Proxy({rt:rt,data:data}, handler1);
        return proxy1;
    }
    console.log(codeplug_format);
    e._cp = data;
    let cp_def = codeplug_format.codeplugs.filter(x=>x.models.includes(radioname))[0];
    if( cp_def == undefined ){
        throw "not a recognized or supported codeplug format";
    }
    for( let r of cp_def.recordTypes ){ //for every record in our relevant codeplug
        let rt = codeplug_format.records.filter(x=>x.type==r)[0];
        //if( rt.typeName == "Contacts" ){ continue; }
        if( rt.max != undefined ){  //records can have arrays
            e[rt.typeName] = make_record_array(rt, data);
        } else {
            let offset = rt.offset;
            let thesebytes = data.subarray(offset, offset+rt.size);
            e[rt.typeName] = make_record(rt, thesebytes);
        }
    }
    return e;
}
let tytera_map = {
    //deviation:"Bandwidth (KHz)",
    name: "Channel Name",
    //talkgroup:"Contact Name", //needs an fn
    //timeslot:"Repeater Slot", //off by one i think
    //color_code:"Color Code",
    //offset:"Tx Offset (MHz)", //needs an fn
    freq:"Rx Frequency (MHz)",
    //need a mode indicator
    //need a type (simplex, repeater) indicator
}

async function apply_json_to_codeplug(zones,editor,codeplug){
    print("start apply " + new Date());
    let newchannels = [];
    for( let c of zones[0].channels ){
        let newc = {};
        for( let k in tytera_map ){
            let t = tytera_map[k];
            if( c[k] ){
                newc[t] = c[k];
            }
        }
        newchannels.push(newc);
    }
    function get_first_empty_channel(editor){
        let cidx = 0;
        while( editor.Channels[cidx]["Channel Name"] != "" ){
            cidx++;
        }
        return cidx;
    }
    console.log(newchannels);
    let cidx = get_first_empty_channel(editor);
    for( const c of newchannels ){
        Object.assign( editor.Channels[cidx], c );
        //console.log(editor.Channels[cidx]);
        cidx++;
    }
}
function check_codeplug_layout(cpfmt){
    for( let cp of cpfmt.codeplugs ){
        let messages = [];
        const calert = (condition,msg)=>{
            if(condition){
                messages.push(msg);
            }
        }
        let fmt = cp.ext;
        let sz = cp[`${fmt}Size`];
        let sections = [];
        for( let r of cp.recordTypes ){
            let rt = cpfmt.records.filter(x=>x.type==r)[0];
            let start = rt.offset;
            let end = start+rt.size;
            if( rt.max ){
                end = start+rt.size*rt.max;
            } 
            sections.push({name:rt.typeName,start:start, end:end});
        }
        sections = sections.sort(sortbykey("start"));
        console.log(cp.type, sections);

        calert( sections[0].start != 0, "Unused beginning of file");
        for( let i = 1; i < sections.length; i++){
            let a = sections[i-1].end;
            let b = sections[i].start;
            calert(a>b, `Overlap between ${sections[i-1].name} and ${sections[i].name}: ${a-b} bytes overlap`);
            calert(a<b, `Missing space after ${sections[i-1].name}: ${b-a} bytes missing`);
        }
        calert( sections[sections.length-1].end != sz, `Unused end of file, ${ sz -sections[sections.length-1].end} missing after ${sections[sections.length-1].name}`);
        console.log(messages);
    }

}
//var cp, z, cpfmt,cpd,e,cpe;
async function codeplug_editor(codeplug, codeplug_format){
    const radioname = ident_codeplug_radio_rdt(codeplug);
    const start = new Date();
    const editor = make_editor(codeplug_format, radioname, codeplug);
    const end = new Date();
    console.log("make_editor elapsed ", (end-start)/1000);
    return editor;
}
export async function readFileBytes(file){
    const data = await readFile(file);
    const bytes = new Uint8Array(data);
    console.log(file,bytes);
    return bytes;
}

async function bin2rdt(dfu, bin){
    const radioidbytes = await dfu.raw_identify_radio();
    //await dfu.enter_dfu_mode();
    const radioname = await dfu.get_radio_name();
    const gen = await dfu.get_radio_gen(radioname);
    const gen1_size = 262709; //these have the header (549) and trailer (16) included;
    const gen2_size = 852533;
    const binsizeoffset = 549+16;
    const rdt_size = gen == 1 ? gen1_size : gen2_size;

    let rdt = new Uint8Array(rdt_size);
    const gen12_split = gen1_size-binsizeoffset;
    const gen1_bin = bin.slice(0,gen12_split);
    const gen2_bin = bin.slice(gen12_split); //to end of bin

    rdt.set( new Uint8Array(549), 0 );
    rdt.set( gen1_bin, 549 );
    rdt.set( new Uint8Array(16), gen12_split+549); //trailer
    rdt.set( gen2_bin, gen1_size); //trailer

    rdt.set( radioidbytes, 0x125 );
    console.log("made an rdt out of it", rdt.length);
    return rdt;
}
async function rdt2bin(rdt){
    const gen1_size = 262709; //these have the header (549) and trailer (16) included;
    const gen2_size = 852533;
    const binsizeoffset = 549+16;
    const gen = [gen1_size,gen2_size].indexOf(rdt.length )+1;
    const gen1_bin = rdt.slice(549, gen1_size-16); 
    const bin_size = rdt.length - binsizeoffset;

    let bin = new Uint8Array(bin_size);
    bin.set(gen1_bin,0);
    if( gen == 2 ){
        const gen2_bin = rdt.slice(gen1_size);
        bin.set(gen2_bin,gen1_bin.length);
    }

    return bin;
}
function bands(ranges){
    let bands = [];
    for( const range of ranges){
        for( const edge of range ){
            const band = rf.freq_to_band(edge/1e6);
            if( ! bands.includes(band) ){
                bands.push(band);
            }
        }
    }
    return bands;
}
let cpj = null;
async function rdt_w_metadata(d, rdt, filename ){
    console.log("metadata add",rdt);
    if( d == null ){
        d = {bands:[], ranges:[]};
    }
    const model = ident_codeplug_radio_rdt(rdt);
    let cpm = {
        readdate: new Date(),
        rdt: rdt,
        model: model,
        bands: d.bands, //TODO need to pull this from rdt and remove the first argument in function
        ranges: d.ranges,
        dmrid: tyt_get_dmrid(rdt),
    };
    if( ! filename ){
        cpm.filename = cp_filename(cpm);
    } else {
        cpm.filename = filename;
    }
    if( cpj == null ){
        cpj = await fetch( "/data/codeplugs.json").then(r=>r.json());
    }
    //TODO cache this somehow
    const e = await codeplug_editor(cpm.rdt, cpj);
    cpm.editor = e;


    const def = {};
    def.overall = cpj.codeplugs.filter(x=>x.models.includes(model))[0];
    def.records = {};
    def.fields = {};
    for( let r of def.overall.recordTypes ){ //for every record in our relevant codeplug
        let rt = cpj.records.filter(x=>x.type == r )[0]
        def.records[rt.typeName] = rt;
        def.records[rt.type] = rt;
        for( let f of rt.fieldTypes ){
            let ft = cpj.fields.filter(x=>x.type == f )[0]
            def.fields[ft.typeName] = ft;
            def.fields[ft.type] = ft;
        }
    }
    //console.log(def);
    cpm.def = def;
    console.log(cpj, e);
    return cpm;
}
function tyt_get_dmrid(cp){
    console.log("get_dmrid",cp);
    const id = uint8s_to_int(cp.slice(549+0x2084, 549+0x2084+4));
    console.log("get_dmrid",cp,id);
    return id;
}
function cp_filename(cp){
    if( cp.filename ){
        return cp.filename;
    }
    const t = cp.readdate;
    let ts = [
        t.getFullYear(),
        t.getMonth()+1, //wtf javascript
        t.getDate(),    //not getDay, mind you
        t.getHours(),
        t.getMinutes()
    ].map(x=>String(x).padStart(2,"0")).join("");
    return `${ts}_${cp.model}_${cp.bands.join("")}_${cp.dmrid}.rdt`;
}

//rewrite make_editor to use es6 proxies
//https://medium.com/@xoor/an-introduction-to-es6-proxies-acc6b59c713b
//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
//https://github.com/vuejs/vue/issues/4384

//web workers:
//https://developers.google.com/web/fundamentals/primers/service-workers/
//https://www.html5rocks.com/en/tutorials/workers/basics/
function make_codeplug_proxy(cp){
    console.log(cp);
    const handler1 = {
        get: function(target, prop, receiver ){
            console.log(receiver, prop);
            return receiver;
        },
        set: function(target, prop, receiver ){
            console.log(receiver, prop);
            return receiver;
        }
    };
    //have proxys all the way down to the records/record arrays?
    //the goal being to not have 100000 proxies, just like, idk, 50?
    const proxy1 = new Proxy(cp, handler1);
    console.log("PROXY",proxy1);
}
export default {
    make_editor,
    make_codeplug_proxy,
    ident_codeplug_radio_rdt,
    codeplug_editor,
    bin2rdt,
    rdt2bin,
    readFileBytes,
    uint8s_to_int,
    rdt_w_metadata,
    cp_filename,
    bands,
    hex2bytes,
    bytes2hex,
    bytes2string,
    js2cstr,
    cstr2js,
    assemble_le,
};
